h1,h2,h3,h4,h5,h6{
  font-family: 'Montserrat', sans-serif;
}
body, p, a{
  font-family: 'Roboto', sans-serif;
}
.form-control {
  outline: 0 !important;
  box-shadow: none !important;
}
a{
  color: inherit !important;
  text-decoration: none !important;
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 1111;
  padding: 10px 15px;
  width: 100%;
}

.autocomplete-dropdown-container p{
  padding: 5px;
  margin-bottom: 0;
}
input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
}
.navigationComman{
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}

.navigationComman i{
  position: absolute;
  left: 0;
  cursor: pointer;
}

.bookingForm {
  position: relative;
}


.slider {
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.sliderBackdrop{
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.sliderBackdrop.open {
  transform: translateX(0);
}

.slider.open {
  transform: translateX(0);
}

.map-container
{
        /* width: 100%; */
        height: 100%;
        position: relative;
        overflow: hidden;
}
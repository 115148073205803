.alert-container .alert {
  /* position: fixed; */
  top: 50%;
  left: 50%;
  margin-left: -135px;
  margin-top: -50px;
  width: 270px;
  text-align: center;
  font-family: -apple-system, SF UI Text, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.4;
  border-radius: 13px;
  overflow: hidden;
  z-index: 9999999999999999999999998;
  background-color: #fff;
}
.alert-container .alert .inner {
  padding: 15px;
}
.alert-container .alert .title {
  font-weight: 500;
  font-size: 18px;
}
.alert-container .alert .text {
  margin-top: 5px;
}
.alert-container .alert .button {
  position: relative;
  height: 44px;
  line-height: 44px;
  font-size: 17px;
  color: #007aff;
  border-radius: 0 0 13px 13px;
  overflow: hidden;
  cursor: pointer;
}
.alert-container .alert * {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -ms-touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.alert-container .alert .button:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 1px;
  width: 100%;
  display: block;
  background-color: #c4c4c4;
  z-index: 9999999999999999999999999;
}
.customError.alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 15px;
  
  background: #f5c2c7ba;
}

.alert-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #0009;
  z-index: 11;
}
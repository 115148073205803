.sidebarInner ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebarInner ul li {
    background: #eee;
    padding: 17px;
}

.sidebarInner > p {
    padding: 16px 15px 7px 15px;
    font-size: 23px;
}

.sidebar {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* background: #00000075; */
    z-index: 111;
}

.sidebarInner {
    width: 80%;
    background: #fff;
    max-width: 300px;
    box-shadow: 2px 0 16px 0 rgb(0 0 0 / 40%);
    position: absolute;
    bottom: 0;
    top: 0;
}

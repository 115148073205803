.userDetails {
    background: #fff;
    padding: 25px;
    height: 100vh;
}

.userDetails .form-group input, .userDetails .form-group select {
    border-radius: 0;
    box-shadow: none !important;
}

.weekoff label {
    background: #000;
    padding: 8px 12px;
    border-radius: 30px;
    color: #fff;
    font-size: 12px;
    margin-right: 5px;

}

.weekoff input {
    display: none;
}

.weekoff label {
    cursor: pointer;
}

.weekoff not(last-child) {
    margin-right: 5px;
}

.weekoff input[type="checkbox"]:checked+label {
    color: lime;
}
.editbutton{
    position: absolute;
    right: 0;
    top: 0;
}
.loginContainer{
    position: relative;
    padding: 25px;
    text-align: center;
    margin: 8% auto 16px;
    width: 372px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
}
.backToHome{
    position: absolute;
    left: 26px;
}

.formGroup {
    position: relative;
    margin-bottom: 14px;
}

.formGroup input {
    padding: 12px;
    padding-left: 65px;
}

.formGroup span {
    position: absolute;
    left: 11px;
    top: 50%;
    transform: translate(0%, -50%);
    font-size: 11px;
    font-weight: bold;
}

ul.vehcleUL {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.vehcleUL li {
    display: flex;
    background: #fff;
    padding: 8px 15px;
    margin-bottom: 10px;
    align-items: center;
}

ul.vehcleUL li img {
    height: auto;
    width: 50px;
    margin-right: 26px;
}

ul.vehcleUL li div {
    position: relative;
    top: 8px;
}

ul.Booking_vehcleUL__DJXr0 li {
    display: flex;
    background: #fff;
    padding: 8px 15px;
    margin-bottom: 10px;
    align-items: center;
}

ul.Booking_vehcleUL__DJXr0 li img {
    height: auto;
    width: 50px;
    margin-right: 26px;
}

ul.Booking_vehcleUL__DJXr0 li div {
    position: relative;
    top: 8px;
}

ul.sharingCheckbox {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
}

ul.sharingCheckbox li {
    background: #000;
    padding: 8px 12px;
    border-radius: 30px;
    color: #fff;
    font-size: 10px;
}

ul.sharingCheckbox li input {
    display: none;
}

ul.sharingCheckbox li label {
    cursor: pointer;
}

ul.sharingCheckbox li:not(last-child) {
    margin-right: 5px;
}

ul.sharingCheckbox li input[type="radio"]:checked+label {
    color: lime;
}

.vehcleUL input {
    display: none;
}

.vehcleUL label {
    width: 100%;    
    cursor: pointer;
}

.vehcleUL input[type="radio"]:checked+label::after {
    content: "\f058";
    font-family: 'FontAwesome';
    position: absolute;
    top: 50%;
    right: 17px;
    transform: translate(0%, -58%);
    font-size: 27px;
    color: lime;
}

.timeContainer label{
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
}


ul.vehcleUL label {
    position: relative;
}
